﻿/* share42.com | 09.09.2013 | (c) Dimox */
(function ($) {
    $(function () {
        $('div.share42init').each(function (idx) {
            var el = $(this),
                u = el.attr('data-url'),
                t = el.attr('data-title'),
                i = el.attr('data-image'),
                d = el.attr('data-description'),
                f = el.attr('data-path');
            if (!u) u = location.href;

            function dlcs_count(url) {
                var shares;
                $.getJSON('http://feeds.delicious.com/v2/json/urlinfo/data?callback=?&url=' + url, function (data) {
                    shares = data[0] ? data[0].total_posts : 0;
                    el.find('a[data-count="dlcs"]').html('<span>' + shares + '</span>');
                });
            }
            dlcs_count(u);

            function fb_count(url) {
                var shares;
                $.getJSON('http://graph.facebook.com/?callback=?&ids=' + encodeURIComponent(url), function (data) {
                    shares = (data[url].shares || 0);
                    el.find('a[data-count="fb"]').html('<span>' + shares + '</span>');
                });
            }
            fb_count(u);

            function pin_count(url) {
                var shares;
                $.getJSON('http://api.pinterest.com/v1/urls/count.json?callback=?&url=' + url, function (data) {
                    shares = data.count;
                    el.find('a[data-count="pin"]').html('<span>' + shares + '</span>');
                });
            }
            pin_count(u);

            function twi_count(url) {
                var shares;
                $.getJSON('http://urls.api.twitter.com/1/urls/count.json?callback=?&url=' + url, function (data) {
                    shares = data.count;
                    el.find('a[data-count="twi"]').html('<span>' + shares + '</span>');
                });
            }
            twi_count(u);
            if (!f) {
                function path(name) {
                    var sc = document.getElementsByTagName('script'),
                        sr = new RegExp('^(.*/|)(' + name + ')([#?]|$)');
                    for (var i = 0, scL = sc.length; i < scL; i++) {
                        var m = String(sc[i].src).match(sr);
                        if (m) {
                            if (m[1].match(/^((https?|file)\:\/{2,}|\w:[\/\\])/)) return m[1];
                            if (m[1].indexOf("/") == 0) return m[1];
                            b = document.getElementsByTagName('base');
                            if (b[0] && b[0].href) return b[0].href + m[1];
                            else return document.location.pathname.match(/(.*[\/\\])/)[0] + m[1];
                        }
                    }
                    return null;
                }
                f = path('share42.js');
            }
            if (!t) t = document.title;
            if (!d) {
                var meta = $('meta[name="description"]').attr('content');
                if (meta !== undefined) d = meta;
                else d = '';
            }
            u = encodeURIComponent(u);
            t = encodeURIComponent(t);
            t = t.replace(/\'/g, '%27');
            i = encodeURIComponent(i);
            d = encodeURIComponent(d);
            d = d.replace(/\'/g, '%27');
            var fbQuery = 'u=' + u;
            if (i != 'null' && i != '') fbQuery = 's=100&p[url]=' + u + '&p[title]=' + t + '&p[summary]=' + d + '&p[images][0]=' + i;
            var s = 
new Array('"#" data-count="fb" onclick="window.open(\'http://www.facebook.com/sharer.php?' + fbQuery + '\', \'_blank\', \'scrollbars=0, resizable=1, menubar=0, left=100, top=100, width=550, height=440, toolbar=0, status=0\');return false" class="FB" title="Share on Facebook"', '"#" onclick="window.open(\'https://plus.google.com/share?url=' + u + '\', \'_blank\', \'scrollbars=0, resizable=1, menubar=0, left=100, top=100, width=550, height=440, toolbar=0, status=0\');return false" class="GP" title="Share on Google+"', '"#" data-count="twi" onclick="window.open(\'https://twitter.com/intent/tweet?text=' + t + '&url=' + u + '\', \'_blank\', \'scrollbars=0, resizable=1, menubar=0, left=100, top=100, width=550, height=440, toolbar=0, status=0\');return false" class="TW" title="Share on Twitter"', '"#" onclick="window.open(\'http://www.blogger.com/blog_this.pyra?t&u=' + u + '&n=' + t + '\', \'_blank\', \'scrollbars=0, resizable=1, menubar=0, left=100, top=100, width=550, height=440, toolbar=0, status=0\');return false" class="BL" title="Post to your blog on blogger.com"', '"http://www.stumbleupon.com/submit?url=' + u + '&title=' + t + '" class="su" title="Share on StumbleUpon"', '"#" data-count="pin" onclick="window.open(\'http://pinterest.com/pin/create/button/?url=' + u + '&media=' + i + '&description=' + t + '\', \'_blank\', \'scrollbars=0, resizable=1, menubar=0, left=100, top=100, width=600, height=300, toolbar=0, status=0\');return false" class="pint" title="Share on Pinterest"', '"#" data-count="dlcs" onclick="window.open(\'http://delicious.com/save?url=' + u + '&title=' + t + '&note=' + d + '\', \'_blank\', \'scrollbars=0, resizable=1, menubar=0, left=100, top=100, width=710, height=660, toolbar=0, status=0\');return false" class="dl" title="Share on Delicious"', '"http://digg.com/submit?url=' + u + '" class="digg" title="Share on Digg"', '"http://reddit.com/submit?url=' + u + '&title=' + t + '" class="rdd" title="Share on Reddit"');
            var l = '';
            for (j = 0; j < s.length; j++) l += '<a rel="nofollow" style="display:block;width:71px;height:26px;margin:0 6px 6px 0;padding:0;outline:none;" href=' + s[j] + ' target="_blank"></a>';
            el.html('<span id="share42" style="display:block;">' + l + '</span>');
        })
    })
})(jQuery);