function bonus_review() {
    0 < $(".big_banner").length && ($(".close_review_button").click(function () {
        $(".review_container").slideUp("fast");
        $(".big_banner .current").removeClass("current");
        return !1
    }), $(".banner_container .bonus_banner").click(function () {
        if (!$(this).hasClass("noclick")) {
            var e = $(this).attr("data-number");
            $(".review[data-number=" + e + "]").hasClass("current") ? $(".review_container").slideUp("fast", function () {
                $(".review[data-number=" + e + "]").removeClass("current")
            }) : ($(".review_container").slideUp("fast"),
                $(".big_banner .current").removeClass("current"), $(".review[data-number=" + e + "]").addClass("current"), $(this).parent().siblings(".review_container").slideDown("fast"));
            return !1
        }
    }))
}

function fly_banners_slider(e) {
    function v(e) {
        $(".fly_banners a.current").removeClass("current");
        $(".fly_banners a:nth-child(" + e + ")").addClass("current");
        $(".fly_banners .fly_control a:nth-child(" + e + ")").addClass("current")
    }

    var k = $(".fly_banners .fly_control > a").length, r = setInterval(function () {
        e++;
        e > k && (e = 1);
        v(e)
    }, 6E3);
    $(".fly_banners a.next_slide").click(function () {
        e++;
        var k = $(".fly_banners .fly_control > a").length;
        e > k && (e = 1);
        $(".fly_banners a.current").removeClass("current");
        $(".fly_banners a:nth-child(" +
            e + ")").addClass("current");
        $(".fly_banners .fly_control a:nth-child(" + e + ")").addClass("current");
        return !1
    });
    $(".fly_banners a.prev_slide").click(function () {
        e--;
        var k = $(".fly_banners .fly_control > a").length;
        1 > e && (e = k);
        $(".fly_banners a.current").removeClass("current");
        $(".fly_banners a:nth-child(" + e + ")").addClass("current");
        $(".fly_banners .fly_control a:nth-child(" + e + ")").addClass("current");
        return !1
    });
    $(".fly_banners .fly_control a").click(function () {
        e = $(this).html();
        $(".fly_banners a.current").removeClass("current");
        $(".fly_banners a:nth-child(" + e + ")").addClass("current");
        $(this).addClass("current");
        return !1
    });
    $(".fly_banners").hover(function () {
        clearInterval(r)
    }, function () {
        r = setInterval(function () {
            e++;
            e > k && (e = 1);
            v(e)
        }, 6E3)
    })
}

function flying_banner(e) {
    if ($(window).scrollTop() >= e) {
        $(".fly_banners").css("position", "fixed");
    } else {
        $(".fly_banners").css("position", "relative");
    }
}

function cas_reviews_view_all() {
    if ($('#cas_reviews').length > 0) {
        $('#cas_reviews').find('.view_all').click(function(){
            $(this).prev('ul').find('li').slideDown('fast');
            $(this).closest('.container').animate({'padding-bottom':0},'fast');
            $(this).slideUp('fast');
            return false;
        });
    }
}

//monster_sania_hover
function monster_sania_hover() {
    if ($('#iframe').length>0){
        $('#iframe .likes_buttons noindex:nth-child(1)').hover(function() {
            $('#iframe .likes_buttons').css('background-position','left 100%');
            $('#iframe .likes_buttons noindex:nth-child(2)').css('top','-2px');
            $('#iframe .likes_buttons noindex:nth-child(1)').css('top','16px');
        },function(){
            $('#iframe .likes_buttons').css('background-position','left top');
            $('#iframe .likes_buttons noindex').css('top','7px');

        });

        $('#iframe .likes_buttons noindex:nth-child(2)').hover(function() {
            $('#iframe .likes_buttons').css('background-position','left 50%');
            $('#iframe .likes_buttons noindex:nth-child(1)').css('top','-2px');
            $('#iframe .likes_buttons noindex:nth-child(2)').css('top','16px');
        },function(){
            $('#iframe .likes_buttons').css('background-position','left top');
            $('#iframe .likes_buttons noindex').css('top','7px');
        });
    }
}

//anchor and autoresize
function anchor() {
    
    var button_offset = $('#iframe').offset().top;
    var window_height=$(window).height();

    $('body,html').animate({scrollTop : ((button_offset + 420 + 69)-window_height)},'fast');
}

function game_autoresize() {
    if (!$('#iframe .game').length > 0){
        var win_width = $(window).width();
        var win_height = $(window).height();

        if (win_height < 646) {
            var offset_sania = $('#iframe').offset().left;
            var offset_content = $('#content').offset().left;
            var frame_width = (win_height-83)*4/3;
            $('#iframe').css('width',frame_width).css('height', win_height);
            $('#iframe iframe').css('width',frame_width).css('height', (win_height-83));
            $('#iframe .likes_buttons').css('left',offset_content-offset_sania+34);
        }else {
            $('#iframe').css('width', 750).css('height', 643);
            $('#iframe iframe').css('width', 750).css('height', 563);
            $('#iframe .likes_buttons').css('left',34);
        }
    }
}

//слайдер на странице игры
function slots_slider() {
    $('.slots_slider').slick({
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: '.prev_slide',
        nextArrow: '.next_slide',
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
}

//Сообщение про куки
function cookie_msg(){
	var cookie_user, user_ip;
		$.getJSON("http://jsonip.com/?callback=?", function (data) {
			var user_str = data.ip.replace(/\./g,"_");
			user_str = String(user_str);
			cookie_user = $.cookie(user_str);
			if(cookie_user == 'ok'){
				$('.cookie_msg').fadeOut();
			}else{
				$('.cookie_msg').fadeIn()();
			}
		});
} 

//Кнопка куки
function btn_cookie(){
	$('.btn_msg').bind('click', function(){
			var cookie_user, user_ip;
			$.getJSON("http://jsonip.com/?callback=?", function (data) {
				var user_str = data.ip.replace(/\./g,"_");
				user_str = String(user_str);
				cookie_user = $.cookie(user_str);
				if(cookie_user != 'ok'){
					$.cookie(user_str, 'ok');
					$('.cookie_msg').fadeOut();
				}
			});
		})
}

$(document).ready(function () {
	
	//Сообщение про куки
	cookie_msg();
	//Кнопка куки
	btn_cookie();
	
	if($("input[name='access']").length > 0){
		$("input[name='access']").val('up');
	}
	
	
    //кнопка вверх
    $(window).scroll(function () {
        200 <= $(window).scrollTop() ? $("#btnUp").fadeIn('slow') : $("#btnUp").fadeOut('slow');
    });
    $("#btnUp").click(function () {
        $("body,html").animate({scrollTop: 0}, "fast");
        return false;
    });

    //мобильная форма поиска
    $('.search_form').submit(function(){
        var value = $('.search_form input[type="text"]').val();
        if (value == '') {return false;}
    });
    $('.search_form').click(function(){
        $(this).addClass('form_open');
    });
    $('.search_form input[type="text"]').focusout(function(){
        var value = $(this).val();
        if (value == '') {
            $('.search_form').removeClass('form_open');
        }
    });

    //мобильное меню
    $('.mob_nav_btn').click(function() {
        $(this).toggleClass('active');
        $('.nav').toggleClass('open');
    });

    //звёзды рейтинга
    $('.rating_stars').rating();

    //показать скрытый текст на странице бонусов
    bonus_review();

    //слайдер на странице игры
    slots_slider();

    //плавающий баннер на боковой панеле
    if (0 < $(".fly_banners").length) {
        var e = $(".fly_banners").offset().top;
        flying_banner(e);
        $(window).scroll(function () {
            flying_banner(e);
        });
        fly_banners_slider(1);
    }

    //показать все, на странице казино
    cas_reviews_view_all();

    //монстр с лайками
    monster_sania_hover();

    //показать все, на странице способов оплаты
    $(".read a").click(function()
    {
        if ($(this).hasClass("active"))
        {
            $(this).addClass("inactive").removeClass("active");
            $(this).closest("div.read").find("div.block").slideUp("fast").addClass("hidden").removeClass("block");
        }
        else
        {
            $(".block").slideUp("fast").addClass("hidden").removeClass("block");
            $(".active").addClass("inactive").removeClass("active");
            $(this).addClass("active").removeClass("inactive");
            $(this).closest("div.read").find("div.hidden").slideDown("fast").addClass("block").removeClass("hidden");
        }
        return false;
    });

    //ссылка на изображении
    if( $("#iframe").length > 0)
    {
        $(".content p img").css('cursor','pointer').click(function(){
/*
            var url = $("#iframe a").attr('href');
            document.location.href=url;
*/

            $("body, html").animate({
                scrollTop: $("#iframe").offset().top
            }, 2000);            

        });
    }

    //anchor and autoresize
    if ($('#iframe .game').length > 0) {
        //game_autoresize();
        anchor();
    }

});

$(window).resize(function(){
    if ($('#iframe').length > 0) {
        //game_autoresize();
    }
});
